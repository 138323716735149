export default {
  DASHBOARD: {
    path: '/',
    name: 'dashboard',
    meta: {
      requiresAuth: true,
      main: true
    },
    component: () => import('@ps_views/DashBoard.vue')
  },
  SURVEYS: {
    path: '/surveys',
    name: 'surveys',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/Surveys/index'),
    props: true
  },
  SURVEY_DETAILS: {
    path: '/survey/:surveyId',
    name: 'survey_details',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/Surveys/Detail'),
    props: (route) => ({
      ...route.params
    })
  },
  PUBLISHERS: {
    path: '/publishers',
    name: 'publishers',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/Publishers.vue')
  },
  PUBLISHER_DETAILS: {
    path: '/publisher/:publisherHash',
    name: 'publisher_details',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/publishers/PublisherDetails.vue')
  },
  QUALIFICATIONS: {
    path: '/qualifications',
    name: 'qualifications',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'ps',
      title: 'Qualifications',
      order: 5
    },
    component: () => import('@ps_views/resources/Qualifications'),
    props: true
  },
  APPS: {
    path: '/apps',
    name: 'apps',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/Apps')
  },
  APP_DETAILS: {
    path: '/apps/:appHash',
    name: 'app_details',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/apps/AppDetails.vue')
  },
  PAYMENTS: {
    path: '/payments',
    name: 'payments',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/Payments.vue')
  },
  STATISTICS: {
    path: '/statistics',
    name: 'statistics',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/Statistics.vue')
  },
  USERS: {
    path: '/users',
    name: 'users',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/users')
  },
  REVIEW_USER: {
    path: '/review-user',
    name: 'review_user',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/Users/ReviewUser')
  },
  USER_DETAILS: {
    path: '/user/:userId',
    name: 'user_details',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'ps',
      title: 'User Details',
      order: 0,
      main: true
    },
    component: () => import('@ps_views/users/UserDetails.vue')
  },
  LOGIN: {
    path: '/login',
    name: 'page-login',
    meta: {
      redirectOnLogin: true,
      loading: false,
      layout: 'full'
    },
    component: () => import('@ps_views/pages/Login.vue')
  },
  REGISTER: {
    path: '/register',
    name: 'page-register',
    meta: {
      loading: false,
      layout: 'full'
    },
    component: () => import('@ps_views/pages/Register.vue')
  },
  PAGE_ERROR_404: {
    path: '/error-404',
    name: 'page-error-404',
    meta: {
      loading: false,
      layout: 'full'
    },
    component: () => import('@ps_views/pages/Error404.vue')
  },
  OTHER_PAGES: {
    path: '*',
    redirect: '/error-404'
  },
  FEEDBACK: {
    path: '/feedback',
    name: 'feedback',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/Feedback.vue')
  },
  CHAT: {
    path: '/chat',
    name: 'chat',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/chat/Chat')
  },
  PERFORMANCE_REPORT: {
    path: '/report/performance',
    name: 'performance_report',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/report/PerformanceReport.vue')
  },
  AB_TESTS: {
    path: '/ab-tests',
    name: 'ab_tests',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/AbTests')
  },
  CLAIMS_OVERVIEW: {
    path: '/claims-overview',
    name: 'claims_overview',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/claims_overview')
  },
  ADMIN_DASHBOARD: {
    path: '/admin/dashboard',
    name: 'admin_dashboard',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/admin/Dashboard/Index.vue')
  },
  ADMIN_ALERT_SYSTEM: {
    path: '/alert-system',
    name: 'admin_alert_system',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/admin/AlertSystem/Index.vue')
  },
  DAILY_OVERVIEW: {
    path: '/daily-overview',
    name: 'daily_overview',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/report/DailyOverview.vue')
  },
  DAILY_SURVEY_SYNC: {
    path: '/daily-survey-sync',
    name: 'daily_survey_sync',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/report/DailySurveySync.vue')
  },
  RESPONSE_STATUS: {
    path: '/response-status',
    name: 'response_status',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/ResponseStatus/index.vue')
  },
  RECONCILIATION_PACING: {
    path: '/reconciliation-pacing',
    name: 'reconciliation_pacing',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/ReconciliationPacing/index.vue')
  },
  SURVEY_INVITATIONS: {
    path: '/survey-invitations',
    name: 'survey_invitations',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/survey/SurveyInvitations.vue'),
    props: true
  },
  SURVEY_INVENTORY: {
    path: '/survey-inventory',
    name: 'survey_inventory',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/survey/SurveyInventory.vue'),
    props: true
  },
  RESPONSE_STATUS_STATISTICS: {
    path: '/response-status-statistics',
    name: 'response_status_statistics',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/report/ResponseStatusStatistics.vue'),
    props: true
  },
  TOP_SURVEYS: {
    path: '/top-surveys',
    name: 'top_surveys',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/survey/TopSurveys.vue'),
    props: true
  },
  JOB_MONITORING: {
    path: '/job-monitoring',
    name: 'job_monitoring',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/JobMonitoring'),
    props: true
  },
  SUPPLIER_STATEMENT: {
    path: '/supplier-statement',
    name: 'supplier_statement',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/suppliers/SupplierStatement.vue'),
    props: true
  },
  SURVEY_ATTACKS: {
    path: '/survey-attacks',
    name: 'survey_attacks',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/survey/SurveyAttacks.vue'),
    props: true
  },
  FRAUD_REVIEW_STATISTICS: {
    path: '/fraud-review-statistics',
    name: 'fraud_review_statistics',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/fraud/FraudReviewStatistics.vue'),
    props: true
  },
  ACTIVITY_BREAKDOWN: {
    path: '/activity-breakdown',
    name: 'activity_breakdown',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/activity_breakdown'),
    props: true
  },
  GEO_MAPPING: {
    path: '/geo-mapping',
    name: 'geo_mapping',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/GeoMapping')
  },
  LATENCY_LOGS: {
    path: '/latency-logs',
    name: 'latency_logs',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/LatencyLog')
  },
  USER_BAN_STATISTICS: {
    path: '/user-ban-statistics',
    name: 'user_ban_statistics',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/report/UserBanStatistics.vue'),
    props: true
  },
  DEVICE_RESULTS: {
    path: '/device-results',
    name: 'device_results',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/DeviceResults'),
    props: true
  },
  TIMEZONE_RESULTS: {
    path: '/timezone-results',
    name: 'timezone_results',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/TimezoneResults'),
    props: true
  },
  QUEUE_ALERT_SYSTEM: {
    path: '/queue-alert-system',
    name: 'queue_alert_system',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/QueueAlertSystem')
  },
  SUPPORT_PERFORMANCE: {
    path: '/support-performance',
    name: 'support_performance',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/report/SupportPerformance.vue'),
    props: true
  },
  USER_PROFILING_SEARCH: {
    path: '/user-profiling-search',
    name: 'user_profiling_search',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/UserProfiling/UserProfilingSearch.vue'),
    props: true
  },
  SUPPLIER_TOP_SURVEYS: {
    path: '/supplier-top-surveys',
    name: 'supplier_top_surveys',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/survey/SupplierTopSurveys.vue'),
    props: true
  },
  CINT_IMPORTS: {
    path: '/cint-imports',
    name: 'cint_imports',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/CintImports'),
    props: true
  },
  HIGH_RISK_METRICS: {
    path: '/high-risk-metrics',
    name: 'high_risk_metrics',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/IpLogs/HighRiskMetrics.vue')
  },
  POSTBACK_LOGS: {
    path: '/postback-logs',
    name: 'postback_logs',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/PostbackLog')
  },
  IP_LOG: {
    path: '/ip-logs',
    name: 'ip_logs',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'ps',
      title: 'IP Logs',
      queryFilterObject: true,
      order: 8
    },
    component: () => import('@ps_views/resources/IpLogs'),
    props: true
  },
  ADMIN_LOG: {
    path: '/admin-logs',
    name: 'admin_logs',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/AdminLogs'),
    props: true
  },
  IPLOGSONE: {
    path: '/iplogsone',
    name: 'iplogsone',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/IpLogsOne/index'),
    props: true
  },
  IPLOGSTWO: {
    path: '/iplogstwo',
    name: 'iplogstwo',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/IpLogsTwo/index'),
    props: true
  },
  IPLOGSTHREE: {
    path: '/iplogsthree',
    name: 'iplogsthree',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/IpLogsThree/index'),
    props: true
  },
  RECONCILIATION_CHART: {
    path: '/reconciliation-chart',
    name: 'reconciliation_chart',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/report/ReconciliationChart.vue')
  },
  PUBLISHER_TRANSACTIONS: {
    path: '/publisher-transactions',
    name: 'publisher_transactions',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/PublisherTransactions')
  },
  PUBLISHER_PAYMENTS: {
    path: '/publisher-payments',
    name: 'publisher_payments',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/PublisherPayments')
  },
  APP_SUPPLIER_SETTINGS: {
    path: '/app-supplier-settings',
    name: 'app_supplier_settings',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/app_supplier_settings')
  },
  PRIME_SAMPLE_BUYERS: {
    path: '/prime-sample-buyers',
    name: 'prime_sample_buyers',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/prime_sample/Buyers')
  },
  COMPLETES_REPORT: {
    path: '/report/completes',
    name: 'completes_report',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/report/CompletesReport.vue')
  },
  DYNATA_TOP_SURVEYS: {
    path: '/dynata-top-surveys',
    name: 'dynata_top_surveys',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/survey/DynataTopSurveys.vue')
  },
  FINANCIAL_REPORT: {
    path: '/financial-report',
    name: 'financial_report',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/prime_opinion/Financials')
  },
  SUPPLIER_STATISTICS: {
    path: '/supplier-statistics',
    name: 'supplier_statistics',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/suppliers/SupplierStatistics.vue')
  },
  SURVEY_LIST_RELOAD_STATISTICS: {
    path: '/survey-list-reload-statistics',
    name: 'survey_list_reload_statistics',
    meta: {
      requiresAuth: true,
      title: 'Survey List Reload Statistics'
    },
    component: () => import('@ps_views/SurveyListReloadStatistics.vue')
  },
  CRONJOB_LOGS: {
    path: '/cronjob-logs',
    name: 'cronjob_logs',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/CronjobLogs')
  },
  MULTI_SEARCH_USER: {
    path: '/multi-search-users',
    name: 'multi_search_users',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/users/MultiSearchUser.vue'),
    props: true
  },
  MULTI_SEARCH_CLICK: {
    path: '/multi-search-clicks',
    name: 'multi_search_clicks',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/clicks/MultiSearchClick'),
    props: true
  },
  RECONCILIATION_REPORT: {
    path: '/report/reconciliation',
    name: 'reconciliation_report',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/report/ReconciliationReport.vue')
  },
  SUPPLIERS: {
    path: '/suppliers',
    name: 'suppliers',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/Suppliers.vue')
  },
  SUPPLIER_DETAILS: {
    path: '/supplier/:supplierId',
    name: 'supplier_details',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/suppliers/SupplierDetails.vue'),
    props: (route) => ({
      ...route.params
    })
  },
  SUPPLIER_BUYERS: {
    path: '/supplier-buyers',
    name: 'supplier_buyers',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/SupplierBuyers.vue')
  },
  SUPPLIER_BUYER_DETAILS: {
    path: '/supplier-buyer/:supplierBuyerId',
    name: 'supplier_buyer_details',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/supplier_buyers/SupplierBuyerDetails.vue'),
    props: (route) => ({
      ...route.params
    })
  },
  QUESTION_DETAILS: {
    path: '/questions/:questionId',
    name: 'question_details',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/qualification_questions/Details.vue'),
    props: (route) => ({
      ...route.params
    })
  },
  QUESTIONS: {
    path: '/questions',
    name: 'questions',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/QualificationQuestions.vue')
  },
  QUESTIONS_MAPPING: {
    path: '/questions-mapping',
    name: 'questions_mapping',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/questions_mapping/QuestionsMapping.vue')
  },
  SURVEY_PUBLISHERS: {
    path: '/survey-publishers',
    name: 'survey_publishers',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/survey_publishers/Index.vue')
  },
  QUESTIONS_MAPPING_STORE: {
    path: '/questions-mapping/add',
    name: 'questions_mapping_add',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/questions_mapping/EditorQuestionsMapping.vue')
  },
  QUESTIONS_MAPPING_DETAILS: {
    path: '/questions-mapping/:questionMappingId',
    name: 'questions_mapping_details',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/questions_mapping/EditorQuestionsMapping.vue')
  },
  QUESTIONS_MAPPING_COPY: {
    path: '/questions-mapping/:questionMappingId/copy/:targetCountryCode',
    name: 'questions_mapping_copy',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/questions_mapping/EditorQuestionsMapping.vue')
  },
  AGE_QUESTIONS: {
    path: '/age-questions',
    name: 'age_questions',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/AgeQuestions')
  },
  ADMINS: {
    path: '/admins',
    name: 'admins',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/Admins.vue')
  },
  HOME_CONTACTS: {
    path: '/home-contacts',
    name: 'home_contacts',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/HomeContacts.vue')
  },
  SURVEY_GROUPS: {
    path: '/survey-groups',
    name: 'survey_groups',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/SurveyGroups.vue')
  },
  CONFIGS: {
    path: '/configs',
    name: 'configs',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/configs/Configs.vue')
  },
  SURVEY_LIST_RELOADS: {
    path: '/survey-list-reloads',
    name: 'survey_list_reloads',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'ps',
      title: 'Survey List Reload Log',
      order: 4
    },
    component: () => import('@ps_views/SurveyListReloads.vue')
  },
  USER_REVIEWS: {
    path: '/user-reviews',
    name: 'user_reviews',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/users/UserReviews')
  },
  USER_STATUS_CHANGES: {
    path: '/user-status-changes',
    name: 'user_status_changes',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/users/UserStatusChanges.vue')
  },
  SURVEY_ATTACKS_OPEN_ENDS : {
    path: '/survey-attacks-open-ends',
    name: 'survey_attacks_open_ends',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/survey/SurveyAttacksOpenEnds.vue')
  },
  USER_FILTER_SEARCH: {
    path: '/user-filter-search',
    name: 'user_filter_search',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/users/UserFilterSearch.vue')
  },
  DEV_LOGS: {
    path: '/dev-logs',
    name: 'dev_logs',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/DevLogs.vue')
  },
  SHELL_LOGS: {
    path: '/shell-logs',
    name: 'shell_logs',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/ShellLogs.vue')
  },
  SYSTEM_LOGS: {
    path: '/system-logs',
    name: 'system_logs',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/SystemLogs')
  },
  RECONCILIATION_LOGS: {
    path: '/reconciliation-logs',
    name: 'reconciliation_logs',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/ReconciliationLogs')
  },
  CLICKS: {
    path: '/clicks/:clickId?',
    name: 'clicks',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'ps',
      title: 'Clicks',
      order: 1
    },
    component: () => import('@ps_views/resources/Clicks'),
    props: true
  },
  CLICK_TICKETS: {
    path: '/click-tickets',
    name: 'click_tickets',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'ps',
      title: 'Tickets',
      order: 6
    },
    component: () => import('@ps_views/ClickTickets.vue')
  },
  USER_CHATS: {
    path: '/chat',
    name: 'chat',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'ps',
      title: 'Chats',
      order: 7,
      queryChatFilters: {
        status: ['open', 'snoozed', 'closed'],
        by_user_id: true
      }
    },
    component: () => import('@ps_views/chat/Chat')
  },
  COMPLETES: {
    path: '/completes',
    name: 'completes',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'ps',
      title: 'Completes',
      order: 2
    },
    component: () => import('@ps_views/Completes.vue'),
    props: true
  },
  USER_PROFILINGS: {
    path: '/user-profilings',
    name: 'user_profilings',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'ps',
      title: 'Profiling',
      order: 3
    },
    component: () => import('@ps_views/users/UserProfilings.vue')
  },
  BASIC_PROFILINGS: {
    type: 'function',
    path: 'showUserProfilingBasicsModal',
    name: 'user_basic_profilings',
    permission: 'user_profilings',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'ps',
      title: 'Basic Profiling',
      order: 4
    }
  },
  SOURCE_EXTENSION: {
    path: '/source-extension',
    name: 'source_extension',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'ps',
      title: 'Extension Log',
      order: 7
    },
    component: () => import('@ps_views/extensions/SourceExtensionRedirect.vue')
  },
  SYSTEM_ALERTS: {
    path: '/system-alerts',
    name: 'system_alerts',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/SystemAlerts')
  },
  PROXY_DETECTION_LOGS: {
    path: '/proxy-detection-log',
    name: 'proxy_detection_log',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/webhook/SourceClassify.vue'),
    props: true
  },
  ROUTER_LOGS: {
    path: '/router-logs',
    name: 'router_logs',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'ps',
      title: 'Router Log',
      order: 9,
      queryFilterObject: true
    },
    component: () => import('@ps_views/RouterLogs.vue')
  },
  RESEARCH_DEFENDER_LOG: {
    path: '/research-defender-log',
    name: 'research_defender_log',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/ResearchDefenderLog.vue')
  },
  ADMIIN_LOG: {
    path: '/admin-logs',
    name: 'admin_logs',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/AdminLogs'),
    props: true
  },
  CINT_DELIVERY_SHARE: {
    path: '/cint-delivery-share',
    name: 'cint_delivery_share',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/cint_delivery_share')
  },
  CHAT_DASHBOARD: {
    path: '/chat-dashboard',
    name: 'chat_dashboard',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/chat/Dashboard.vue')
  },
  JOB_LOGS: {
    path: '/job-logs',
    name: 'job_logs',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/JobLog')
  },
  FIND_DATA: {
    path: '/find-data',
    name: 'find_data',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/find_data')
  },
  CHAT_WEEKLY_PRODUCTIVITY: {
    path: '/chat-weekly-productivity',
    name: 'chat_weekly_productivity',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/chat/WeeklyProductivity.vue')
  },
  CHAT_WEEKLY_CUSTOMER_SATISFACTION: {
    path: '/chat-weekly-csat',
    name: 'chat_weekly_csat',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/chat/WeeklyCustomerSatisfaction.vue')
  },
  CHAT_SERVICE_OVERVIEW: {
    path: '/chat-service-overview',
    name: 'chat_service_overview',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/chat/CustomerServiceOverview.vue')
  },
  USER_PROFILING_LOOKUP: {
    path: '/user-profiling-lookup',
    name: 'user_profiling_lookup',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/resources/UserProfiling/UserProfilingLookup.vue')
  },
  SURVEY_ATTACKS_APPS: {
    path: '/survey-attacks-apps',
    name: 'survey_attacks_apps',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/survey/SurveyAttacksApps.vue'),
    props: true
  }
}